define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-banner", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _i18n, _getUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get backgroundImage() {
      return (0, _template2.htmlSafe)(`background-image: url(${(0, _getUrl.getURLWithCDN)(this.siteSettings.banner_background_image)})`);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="mvp-banner" style={{this.backgroundImage}}>
          <div class="mvp-banner__text">
            <h1>{{i18n "discourse_uipath_mvp.mvp.banner.title"}}</h1>
            <p>{{i18n "discourse_uipath_mvp.mvp.banner.subtitle"}}</p>
          </div>
        </div>
      
    */
    {
      "id": "+EyE0/Dj",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"mvp-banner\"],[15,5,[30,0,[\"backgroundImage\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"mvp-banner__text\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,[28,[32,0],[\"discourse_uipath_mvp.mvp.banner.title\"],null]],[13],[1,\"\\n        \"],[10,2],[12],[1,[28,[32,0],[\"discourse_uipath_mvp.mvp.banner.subtitle\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-banner.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpBanner;
});