define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-summary-topic", ["exports", "@glimmer/tracking", "@ember/template", "discourse/components/user-summary-topic", "discourse/helpers/format-date", "discourse/helpers/number", "discourse/lib/render-tags", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _template2, _userSummaryTopic, _formatDate, _number, _renderTags, _dIcon, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpUserSummaryTopic extends _userSummaryTopic.default {
    get tags() {
      if (this.topic.tags) {
        return (0, _renderTags.default)(this.topic);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "tags", [_tracking.cached]))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="topic-info">
          {{formatDate @createdAt format="tiny" noTitle="true"}}
          {{#if @likes}}
            &middot;
            {{dIcon "heart"}}&nbsp;<span class="like-count">{{number @likes}}</span>
          {{/if}}
          {{#if this.tags}}
            &middot;
            <span class="tags">{{htmlSafe this.tags}}</span>
          {{/if}}
        </span>
        <br />
        <a href={{@url}}>{{htmlSafe @topic.fancyTitle}}</a>
      
    */
    {
      "id": "0HZ2w4FA",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"topic-info\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]]],[1,\"\\n\"],[41,[30,2],[[[1,\"        ·\\n        \"],[1,[28,[32,1],[\"heart\"],null]],[1,\" \"],[10,1],[14,0,\"like-count\"],[12],[1,[28,[32,2],[[30,2]],null]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"tags\"]],[[[1,\"        ·\\n        \"],[10,1],[14,0,\"tags\"],[12],[1,[28,[32,3],[[30,0,[\"tags\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,3],[15,6,[30,3]],[12],[1,[28,[32,3],[[30,4,[\"fancyTitle\"]]],null]],[13],[1,\"\\n  \"]],[\"@createdAt\",\"@likes\",\"@url\",\"@topic\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-summary-topic.js",
      "scope": () => [_formatDate.default, _dIcon.default, _number.default, _template2.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpUserSummaryTopic;
});