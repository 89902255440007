define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-custom-preferences/mvp-profile-preference-fields", ["exports", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _mvpUserPreference, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpProfilePreferenceFields = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="mvp-preferences">
      <MvpUserPreference @user={{@outletArgs.model}} />
    </div>
  
  */
  {
    "id": "WWv4FliN",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"mvp-preferences\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-custom-preferences/mvp-profile-preference-fields.js",
    "scope": () => [_mvpUserPreference.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = MvpProfilePreferenceFields;
});