define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/index", ["exports", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/blog-url", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/company-name", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/country", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/github-username", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/industry-focuses", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/languages", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/linkedin-profile-url", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/location-details", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/role", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/technology-expertises", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/x-username", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/youtube-channel-url", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _blogUrl, _companyName, _country, _githubUsername, _industryFocuses, _languages, _linkedinProfileUrl, _locationDetails, _role, _technologyExpertises, _xUsername, _youtubeChannelUrl, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpUserPreference = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <MvpUserPreferenceCompanyName @user={{@user}} />
    <MvpUserPreferenceRole @user={{@user}} />
    <MvpUserPreferenceIndustryFocuses @user={{@user}} />
    <MvpUserPreferenceTechnologyExpertises @user={{@user}} />
    <MvpUserPreferenceLinkedinProfileUrl @user={{@user}} />
    <MvpUserPreferenceYoutubeChannelUrl @user={{@user}} />
    <MvpUserPreferenceBlogUrl @user={{@user}} />
    <MvpUserPreferenceXUsername @user={{@user}} />
    <MvpUserPreferenceGithubUsername @user={{@user}} />
    <MvpUserPreferenceCountry @user={{@user}} />
    <MvpUserPreferenceLocationDetails @user={{@user}} />
    <MvpUserPreferenceLanguages @user={{@user}} />
  
  */
  {
    "id": "jLM2zMwD",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,2],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,3],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,4],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,5],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,6],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,7],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,8],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,9],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,10],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n  \"],[8,[32,11],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n\"]],[\"@user\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/index.js",
    "scope": () => [_companyName.default, _role.default, _industryFocuses.default, _technologyExpertises.default, _linkedinProfileUrl.default, _youtubeChannelUrl.default, _blogUrl.default, _xUsername.default, _githubUsername.default, _country.default, _locationDetails.default, _languages.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = MvpUserPreference;
});