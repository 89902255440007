define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-post-names/mvp-social-links", ["exports", "@glimmer/component", "@ember/utils", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-social-link", "@ember/component", "@ember/template-factory"], function (_exports, _component, _utils, _mvpSocialLink, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELDS = ["mvp_x_username", "mvp_linkedin_profile_url", "mvp_github_username", "mvp_youtube_channel_url", "mvp_blog_url"];
  class MvpSocialLinks extends _component.default {
    get availableSocials() {
      const mvpFields1 = this.args.outletArgs.model.custom_fields;
      return FIELDS.filter(name1 => (0, _utils.isPresent)(mvpFields1[name1])).map(name1 => {
        return {
          name: name1,
          value: mvpFields1[name1]
        };
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="mvp-social-links">
          {{#each this.availableSocials as |social|}}
            <MvpSocialLink @name={{social.name}} @value={{social.value}} />
          {{/each}}
        </div>
      
    */
    {
      "id": "8JzR4ZR2",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"mvp-social-links\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"availableSocials\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@name\",\"@value\"],[[30,1,[\"name\"]],[30,1,[\"value\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"social\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-post-names/mvp-social-links.js",
      "scope": () => [_mvpSocialLink.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpSocialLinks;
});