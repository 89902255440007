define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-profile-primary/mvp-profile-fields", ["exports", "@glimmer/component", "@ember/utils", "I18n", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-profile-field", "@ember/component", "@ember/template-factory"], function (_exports, _component, _utils, _I18n, _mvpProfileField, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELDS = ["mvp_current_program", "mvp_current_categories", "mvp_technology_expertises", "mvp_industry_focuses"];
  class MvpProfileFields extends _component.default {
    static shouldRender(_1, _ref) {
      let {
        currentUser: currentUser1,
        site: site1
      } = _ref;
      return !site1.mvp_features_restricted || currentUser1?.can_see_mvp_features;
    }
    get customMvpFields() {
      const mvpFields1 = this.args.outletArgs.model.custom_fields;
      return FIELDS.filter(name1 => (0, _utils.isPresent)(mvpFields1[name1])).map(name1 => {
        return {
          name: _I18n.default.t(`discourse_uipath_mvp.user.${name1}`),
          key: name1,
          value: mvpFields1[name1]
        };
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="public-user-fields mvp-profile-fields">
          {{#each this.customMvpFields as |field|}}
            <MvpProfileField @field={{field}} />
          {{/each}}
        </div>
      
    */
    {
      "id": "qr1o5pbJ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"public-user-fields mvp-profile-fields\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"customMvpFields\"]]],null]],null],null,[[[1,\"        \"],[8,[32,0],null,[[\"@field\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"field\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-profile-primary/mvp-profile-fields.js",
      "scope": () => [_mvpProfileField.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpProfileFields;
});